import React, { ReactElement } from 'react';
import Footer, { SocialLinkType, SimpleLinkType } from '@rambler-components/footer';

import { styled } from '@linaria/react';
import '@rambler-components/footer/styles.css';

const FooterUser = styled.div`
  * {
    box-sizing: border-box;
  }
  ul li::before {
    display: none;
  }
`;

const simpleLinks = [
  {
    label: 'Условия использования',
    url: '/legal/1430',
  },
  {
    label: 'Политика конфиденциальности',
    url: '/legal/1142',
  },
];

const socialLinks = [
  {
    code: 'vk',
    url: 'https://vk.com/rambler',
  },
  {
    code: 'ok',
    url: 'https://ok.ru/rambler',
  },
  {
    code: 'tg',
    url: 'https://t.me/news_rambler',
  },
  {
    code: 'rss',
    url: 'https://news.rambler.ru/rss/',
  },
];

const FooterUserComponent = (): ReactElement => {
  return (
    <FooterUser id="footer">
      <Footer
        simpleLinks={simpleLinks as SimpleLinkType[]}
        socialLinks={socialLinks as SocialLinkType[]}
        ageLimit={18}
      />
    </FooterUser>
  );
};

export default FooterUserComponent;
